@import "../../style/colors.scss";

.Loading {
    background-color: $primary;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .loader {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: absolute;
    border: 4px solid $secondary;
    animation: loader 2s infinite ease;
  }
  
  .loader-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: $secondary;
    animation: loader-inner 2s infinite ease-in;
  }
  
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    
    25% {
      transform: rotate(180deg);
    }
    
    50% {
      transform: rotate(180deg);
    }
    
    75% {
      transform: rotate(360deg);
    }
    
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes loader-inner {
    0% {
      height: 0%;
    }
    
    25% {
      height: 0%;
    }
    
    50% {
      height: 100%;
    }
    
    75% {
      height: 100%;
    }
    
    100% {
      height: 0%;
    }
  }