@import "./style/colors.scss";
@import "./style/metrics.scss";

html {
  background-color: $primary;
  height: 100%;
  width: 100vw;
  scroll-behavior: smooth;
  overflow-x: hidden;
  scrollbar-color: $primary;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
  height: 100%;
  background-image: url("./assets//Images//bg.webp");
  background-size: cover;
  overflow-x: visible;
}

@font-face {
  font-family: "Pacifico";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Pacifico Regular"), local("Pacifico-Regular"),
    url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2) format("woff2");
  font-display: swap;
}

code {
  //font-family: source-code-pro, Menlo, Roboto, Monaco, Consolas, "Courier New", monospace;
  font-family: Roboto;
}

#root {
  height: inherit;
}
